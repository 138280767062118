import {useDispatch, useSelector} from 'react-redux'
import {fetchAllInquiries, selectInquiries} from "slices/inquirySlice";
import {useEffect} from "react";

export default function Index() {
  const inquiries = useSelector(selectInquiries)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAllInquiries())
  }, [])

  return(
    <div className="page-content-inner">

      <div className="columns tasks-table">
        <div className="column is-12" style={{width: 'fit-content', flexGrow: 1}}>
          <div className="s-card demo-table">
            <table className="table is-hoverable is-fullwidth">
              <tbody>
              <tr>
                <th>Display Name</th>
                <th>Email</th>
                <th>Company</th>
                <th>Message</th>
              </tr>
              {
                inquiries?.map( (inquiry, index) => (
                    <Inquiry key={index} inquiry={inquiry} />
                  )
                )
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  )
}

function Inquiry({inquiry}) {

  return(
    <tr>
      <td>{inquiry.firstName} {inquiry.lastName}</td>
      <td>{inquiry.email}</td>
      <td>{inquiry.company}</td>
      <td>{inquiry.message}</td>
    </tr>
  )
}
