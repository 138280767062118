// noinspection ES6CheckImport
import {getAuth, onAuthStateChanged, sendPasswordResetEmail,
  signInWithEmailAndPassword, signOut, updateProfile, updatePassword} from "firebase/auth";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import {getToken} from "firebase/messaging";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import React, {useContext, useEffect, useState} from 'react'
import {useFirestore, useStorage} from 'reactfire';
import {messaging} from "firebase.js";
import {store} from "react-notifications-component";
import swal from "sweetalert";
import constants from 'constants.js';
//================================================================

const AuthContext = React.createContext(undefined)

export function useAuth() {
  return useContext(AuthContext)
}


export function AuthProvider({children}) {
  const auth = getAuth()
  const firestore = useFirestore()
  const storage = useStorage()

  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  async function logout() {
    const userDocRef = doc(firestore, "users", currentUser['uid']);
    await updateDoc(userDocRef, {fcm_token: ''})
    return signOut(auth)
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  // TODO
  // function updateEmail(email) {
  // return currentUser.updateEmail(email)
  // }

  async function uploadProfileImage(image) {
    const profileImageRef = ref(storage, `profile_images/${currentUser['uid']}.jpg`);
    const uploadTask = await uploadBytesResumable(profileImageRef, image)
    return await getDownloadURL(uploadTask.ref)
  }

  async function updateUserPassword(password) {
    updatePassword(currentUser, password).then(() => {
      swal('Success', 'Password Updates Successfully!', 'success')
    }).catch((err) => {
      swal('Warning', err.message, 'warning')
    });
  }

  async function updateUserDoc(data) {
    const userDocRef = doc(firestore, "users", currentUser['uid']);
    await updateDoc(userDocRef, data)
  }

  async function updateUserProfile(data) {
    updateProfile(currentUser, data).then(async () => {
      await swal('Success', 'Profile Updates Successfully!', 'success')
      await updateUserDoc(data)
    }).catch((err) => {
      swal('Warning', err.message, 'warning')
    });
  }

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      try {
        if(user) {
          const userDocRef = doc(firestore, "users", user.uid);
          const docSnap = await getDoc(userDocRef);
          if(!docSnap.exists()) throw new Error('Your data is invalid, please contact admin')
          user["data"] = docSnap.data()
          if(user["data"].role !== constants.ADMIN) throw new Error('You cannot login from here')
          // If the user have no photo url uploaded, attach a placeholder
          if(!user.photoURL) user.photoURL = require('assets/img/profile-avatar-placeholder.png')
        }
        setCurrentUser(user)
        setLoading(false)
      } catch (e) {
        await swal('Warning', e.message, 'warning')
        return signOut(auth)
      }
    })
  }, [auth, firestore])

  useEffect(() => {
    //  Add device FCM token
    if (currentUser) {
      getToken(messaging)
        .then( async currentToken => {
          if (currentToken && currentToken !== currentUser['data']['fcm_token'])
            await updateUserDoc({fcm_token: currentToken})
        })
        .catch( (e) => {
          console.log(e)
          store.addNotification({
            title: "Warning",
            message: e.message,
            type: "warning",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              pauseOnHover: true
            }
          });
        })
    }
  }, [currentUser])


  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    updateUserProfile,
    updateUserPassword,
    uploadProfileImage
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
