import Input from 'components/Input';
import SingleSelectPicker from 'components/SingleSelectPicker';
import selectResource from 'hooks/selectResource';
import {fetchUsersByRole, selectUsersByRole} from 'slices/userSlice';

export default function PlanForm({register, control}) {

  const clientsOptions = selectResource(fetchUsersByRole('client'), selectUsersByRole('client'))
    .map(client => ({value: client.id, label: client.displayName}))

  return(
    <div className="modal-form">
      <div className="columns is-multiline">
        <Input label="Start Date" type="datetime-local" register={register} name="start"
               helper="Mandatory" helperClass="help text-danger" validation={{required: true}} />
        <Input label="End Date" type="datetime-local" register={register} name="end" helper="Mandatory"
               helperClass="help text-danger" validation={{required: true}}/>
        <Input label="Title" placeHolder="Ex: A white panner" name="title" helper="Mandatory"
               helperClass="help text-danger" register={register} validation={{required: true}} />
        <div className="column is-6">
          <div className="field">
            <label>Client</label>
            <div className="control">
              <SingleSelectPicker options={clientsOptions} name="client_id" register={register}
                                  control={control} args={{rules: {required: true}}}
                                  selectArgs={{maxMenuHeight: '30vh'}}/>
            </div>
            <p className="help text-danger">Mandatory</p>
          </div>
        </div>
        <Input label="Content" placeHolder="Details about the task..." name="content" helper="Mandatory"
               helperClass="help text-danger" type="textarea"
               options={{rows: 3, required: true}}
               register={register} validation={{required: true}} size="12"
        />
        <Input label="Notes" placeHolder="Additional information about the task..." name="notes"
               helper="Optional" type="textarea" options={{rows: 2}}
               register={register} size="12"
        />
      </div>
    </div>
  )
}
