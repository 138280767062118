import { Redirect, Route } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import Loader from "components/Loader";

export default function AuthRoute({ component: Component, ...rest }) {

  const { currentUser } = useAuth()

  return (
    <>
      <Loader />
      <Route
        {...rest}
        render={props => (currentUser ? <Redirect to='/' /> : <Component {...props} />)} />
    </>
  )
}
