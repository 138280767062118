import {useEffect, useState} from "react";

export default function useDropdown() {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  // Effects
  useEffect(() => {
    setTimeout(() => {
      if(dropdownIsOpen){
        window.addEventListener('click', toggleDropdown)
      }
      else{
        window.removeEventListener('click', toggleDropdown)
      }
    }, 0)
  }, [dropdownIsOpen])

  function toggleDropdown() {
    setDropdownIsOpen(!dropdownIsOpen)
    window.removeEventListener('click', toggleDropdown)
  }

  return [dropdownIsOpen, toggleDropdown];
}
