import {useDispatch, useSelector} from 'react-redux'
import {fetchAllSignUpRequests, selectSignUpRequests} from "slices/signUpRequestSlice";
import {useEffect} from "react";

export default function Index() {
  const signUpRequests = useSelector(selectSignUpRequests)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAllSignUpRequests())
  }, [])

  return(
    <div className="page-content-inner">

      <div className="columns tasks-table">
        <div className="column is-12" style={{width: 'fit-content', flexGrow: 1}}>
          <div className="s-card demo-table">
            <table className="table is-hoverable is-fullwidth">
              <tbody>
              <tr>
                <th>Display Name</th>
                <th>Email</th>
                <th>Status</th>
                <th>Requested Password</th>
              </tr>
              {
                signUpRequests?.map( (signUpRequest, index) => (
                    <SignUpRequest key={index} signUpRequest={signUpRequest} />
                  )
                )
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  )
}

function SignUpRequest({signUpRequest}) {

  return(
    <tr>
      <td>{signUpRequest.displayName}</td>
      <td>{signUpRequest.email}</td>
      <td>{signUpRequest.status}</td>
      <td>{signUpRequest.password}</td>
    </tr>
  )
}
