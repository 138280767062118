// noinspection ES6CheckImport

import { addDoc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import swal from 'sweetalert';

export const create = async (collectionRef, data) => {
  await addDoc(collectionRef, data)
  await swal('Success', `Success!`, 'success', {timer: 1000})
}

export const read = async (docRef) => {
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    await swal('Warning', "Not found", 'warning')
  }
}

export const update = async (docRef, data) => {
  await updateDoc(docRef, data)
  await swal('Success', `Updated!`, 'success', {timer: 1000})
}

export const deleteDocument = async (docRef) => {
  await deleteDoc(docRef)
  await swal('Success', `Deleted!`, 'success', {timer: 1000})
}
