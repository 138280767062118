import useDropdown from 'hooks/useDropdown';
import {useState} from 'react';
import {Controller} from "react-hook-form";

export default function SelectInput({size, label, name, defaultName, defaultValue, options, helper,
                                      helperClass = 'help', control, removable = false}) {

  const [dropdownIsOpen, toggleDropdown] = useDropdown()
  const [selectedOption, setSelectedOption] = useState(null)

  return(
    <div className={`column is-${size}`}>
      <div className="field">
        <label htmlFor={`{${name}-input`}>{label}</label>
        <div className="control" onClick={toggleDropdown}>
          <div className={`h-select is-dropup has-media ${dropdownIsOpen && 'is-active'}`}>
            <div className="select-box">
              <span>{selectedOption || defaultName}</span>
            </div>
            <div className="select-icon">
              <i data-feather="chevron-down"/>
            </div>
            <div className="select-drop has-slimscroll-sm">
              <div className="drop-inner">
                <Controller
                  control={control}
                  defaultValue={defaultValue}
                  name={name}
                  render={({ field: { onChange }}) => (
                    <>
                      { removable && <div className="option-row">
                        <input type="radio" name={name} id={`{${name}-input-0`} onClick={() => {
                          setSelectedOption('None')
                          onChange(null)
                        }}/>
                        <div className="option-meta">
                          <span>None</span>
                        </div>
                      </div>}
                      {options.filter((option) => option.value !== defaultValue)
                        .map((option, index) =>
                          <div className="option-row" key={index}>
                            <input type="radio" name={name} id={`${name}-input-${index+1}}`} onClick={() => {
                              setSelectedOption(option.name)
                              onChange(option.value)
                            }}/>
                            <div className="option-meta">
                              {option.imgURL && <img src={option?.imgURL} alt=""/>}
                              <span>{option.name}</span>
                            </div>
                          </div>
                        )}
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <p className={helperClass}>{helper}</p>
      </div>
    </div>
  )
}
