import { Redirect, Route } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import constants from "constants.js";
import React from "react";
import Loader from "components/Loader";

export default function PrivateRoute({ component: Component, ...rest }) {

  const { currentUser } = useAuth()

  return (
    <>
      <Loader />
      <Route
        {...rest}
        render={props => ( currentUser ? <Component {...props} /> : <Redirect to={`/${constants.AUTH}`}/> )} />
    </>
  )
}
