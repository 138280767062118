import React, {useEffect, useState} from "react";
// Assets
import "assets/scss/page-loader.scss"

export default function Loader() {

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const loadingTimer = setTimeout(() => setLoaded(true), 2000)
    return () => clearTimeout(loadingTimer)
  }, [])

  return(
    <>
      <div className={`page-loader ${!loaded && 'is-active'}`}/>
    </>
  )
}
