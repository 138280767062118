import FeatherIcon from "feather-icons-react";
import {useState, useEffect} from 'react';

import {Controller} from "react-hook-form";

export default function AdditionalFieldsInput({control, name, size = 6, label = 'Additional Information',
                                                defaultValue}) {

  return(
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, ref}}) => (
        <_additionalFieldsInput
          defaultValue={defaultValue}
          onChange={onChange}
          inputRef={ref}
          size={size}
          label={label}
        />
      )}
    />
  )
}

function _additionalFieldsInput({size, label, onChange, defaultValue = {}}) {

  const removeField = (id) => {
    setFields(fields => fields.filter(field => field.props.id !== id))
    setPrototype(oldPrototype => {
      delete oldPrototype[id];
      return oldPrototype;
    })
  }

  // States
  const [prototype, setPrototype] = useState((() => {
    const defaultsObj = {}; let i = 0;
    for (const entry of Object.entries(defaultValue)) {defaultsObj[i] = entry; i++;}
    return defaultsObj;
  })())

  const [fields, setFields] = useState((() => {
    const fieldsArr = []; let i = 0;
    for (const entry of Object.entries(defaultValue)) {
      fieldsArr.push(<Field key={i} id={i} removeField={removeField} prototype={prototype} setPrototype={setPrototype} />)
      i++;
    }
    return fieldsArr;
  })())

  const [index, setIndex] = useState(Object.keys(defaultValue).length +1)

  // Effects
  useEffect(() => {
    const newData = {}
    for (const [, value] of Object.entries(prototype)) {
      if(value[0] && value[1])
        newData[value[0]] = value[1]
    }
    onChange(newData)
  }, [prototype, fields])


  // Methods
  const addField = () => {
    setFields(fields => fields.concat(
      <Field key={index} id={index} removeField={removeField} prototype={prototype} setPrototype={setPrototype} />))
    setPrototype(oldPrototype => ({...oldPrototype, ...{[index]: []}}))
    setIndex(count => count+1)
  }


  return(
    <div className={`column is-${size}`}>
      <div className="field">
        <label>{label}</label>
        {fields}
      </div>
      <div className="is-flex is-justify-content-center">
        <a href="#" className="button" onClick={() => addField()}>
          <span className="icon is-small">
            <FeatherIcon icon={'plus'} />
          </span>
        </a>
      </div>
    </div>
  )
}

function Field({id, removeField, prototype, setPrototype}) {

  const handleKeyChange = (ev) => {
    setPrototype(data => {
      const newFieldValue = data[id]
      newFieldValue[0] = ev.target.value;
      return {...data, ...{[id]: newFieldValue}};
    })
  }

  const handleValueChange = (ev) => {
    setPrototype(data => {
      const newFieldValue = data[id]
      newFieldValue[1] = ev.target.value;
      return {...data, ...{[id]: newFieldValue}};
    })
  }


  return(
    <div className="field has-addons">
      <div className="control is-expanded">
        <input className="input" type="text" placeholder="Field" defaultValue={prototype[id]?.[0]} onChange={handleKeyChange} />
      </div>
      <div className="control is-expanded">
        <input className="input" type="text" placeholder="Value" defaultValue={prototype[id]?.[1]} onChange={handleValueChange} />
      </div>
      <div className="control">
        <a href="#" className="button is-danger is-outlined" onClick={() => removeField(id)}>-</a>
      </div>
    </div>
  )
}

