import UsersIndex from './Index';
import UpdateTeamLeader from 'views/users/UpdateTeamLeader';

export default function TeamLeadersIndex() {

  return(
    <UsersIndex role="team_leader" UpdateComponent={UpdateTeamLeader} />
  )

}
