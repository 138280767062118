import {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';

export default function selectResource(thunk, selector, deps = []) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(thunk)
  }, deps)

  return useSelector(selector);
}
