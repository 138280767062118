import {useEffect, useMemo, useState} from "react";
import {useForm as useBaseForm} from "react-hook-form";
import {useLocation} from 'react-router-dom';
import {useFirestore} from "reactfire";
import {collection} from "firebase/firestore";
import {create} from "API.js";
import swal from 'sweetalert';
//===============================================


export function useImageInput() {
  const [data, setData] = useState({
    file: null,
    imagePreviewUrl: ''
  })

  const handleImageChange = (e) => {
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onloadend = () => {
      setData({
        file: file,
        imagePreviewUrl: reader.result
      })
    }
    reader.readAsDataURL(file)
  }

  const reset = () => {
    setData({
      file: null,
      imagePreviewUrl: ''
    })
  }

  return [handleImageChange, data.imagePreviewUrl, data.file, reset]
}

//================================================================
export function useForm(givenOnSubmit, Model, collectionName) {
  const firestore = useFirestore()
  const { register, handleSubmit, reset } = useBaseForm()

  // Methods
  async function onSubmit(data) {
    try {
      const record = new Model(data)
      record.validate()
      await create(collection(firestore, collectionName), record.data)
      await reset()
    } catch (err) {
      await swal('Warning', err.message, 'warning')
    }
  }

  function handleCancel(e) {
    e.preventDefault()
    reset();
  }

  return {
    register,
    handleSubmit: handleSubmit(givenOnSubmit || onSubmit),
    reset,
    handleCancel,
  }
}

//===============================================================
export function useButtonListener(callback, buttonCode, dependencies = []) {
  useEffect(() => {
    const close = (e) => { if(e.keyCode === buttonCode) callback()}
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, dependencies)
}


//===============================================================
export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
