import {forwardRef} from 'react';
import BaseDatePicker from "react-datepicker"

export default function DatePicker(props) {

  const CustomButton = forwardRef(({ value, onClick }, ref) => (
    <button className="button h-button is-raised is-primary is-outlined" onClick={onClick} ref={ref} disabled={props.disabled}
    style={{padding: '8px 10px'}}>
      {value|| 'Press to pick a date'}
    </button>
  ));

  return (
    <BaseDatePicker
      {...props}
      customInput={<CustomButton />}
      dateFormat={'MMM - yyyy'}
    />
  )
}
