import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

import routes from "routes.js";
import constants from "constants.js";


export default function AuthLayout() {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop['layout'] === constants.AUTH) {
        return (
          <Route
            path={`/${constants.AUTH}${prop.path}`}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="main-content" ref={mainContent}>
      <Switch>
        {getRoutes(routes)}
        <Redirect from="*" to={`/${constants.AUTH}/login`} />
      </Switch>
    </div>
  );
};
