const planConverter = {
  toFirestore: (data) => {
    return {
      ...data, ...{
        start: new Date(data.start),
        end: new Date(data.end)
      }
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      ...data, ...{
        start: data.start?.toDate(),
        end: data.end?.toDate()
      }
    };
  }
}

export default planConverter;
