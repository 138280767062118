import FeatherIcon from 'feather-icons-react';
import {useImageInput} from 'hooks/utils';
import {useEffect, useState} from 'react';
import {getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage';
import {useStorage} from 'reactfire';
import swal from 'sweetalert';
import {doc, updateDoc} from 'firebase/firestore';
import {useFirestore} from 'reactfire';

export default function MediaTab({task, setModalIsLoading}) {

  const [isLoading, setIsLoading] = useState(false)
  const [handleImageChange, imagePreviewUrl, image] = useImageInput()
  const storage = useStorage()
  const firestore = useFirestore()


  useEffect( async () => {
    if(!image) return;
    try {
      setIsLoading(true)
      setModalIsLoading(true)
      const taskRef = doc(firestore, "tasks", task.id)
      const taskImageRef = ref(storage, `tasks/${task.id}.jpg`)
      const uploadTask = await uploadBytesResumable(taskImageRef, image)
      // noinspection JSUnresolvedVariable
      const imageURL = await getDownloadURL(uploadTask.ref)
      // noinspection JSCheckFunctionSignatures
      await updateDoc(taskRef, {imageURL})
      await swal('Success', 'File Uploaded Successfully', 'success', {timer: 1000})
    } catch (err) {
      await swal('Warning', err.message, 'warning')
    } finally {
      setIsLoading(false)
      setModalIsLoading(false)
    }
  }, [image])


  return(
    <div className='tab-content is-active'>
      <img src={imagePreviewUrl || task.imageURL} alt="" />
      <div className="field is-grouped is-justify-content-end">
        <div className="control is-flex ">
          <div className={`file mr-2 ${isLoading && 'is-disabled'}`}>
            <label className="file-label">
              <input className="file-input" type="file" onChange={handleImageChange}/>
              <span className="file-cta">
                <span className="file-icon">
                  <FeatherIcon icon={'upload'} />
                </span>
                <span className="file-label">
                  Upload a File
                </span>
              </span>
            </label>
          </div>
          <div className={`file is-primary  ${isLoading && 'is-disabled'}`}>
            <a className="file-label" href={task.imageURL} download={`Task-${task.order}`}>
              <span className="file-cta">
                <span className="file-icon">
                  <FeatherIcon icon={'download'} />
                </span>
                <span className="file-label">
                  Download File
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
