import FeatherIcon from "feather-icons-react";
import {useState} from 'react';

export default function UserCard({user, UpdateComponent}) {
  const [updateIsOpen, setUpdateIsOpen] = useState(false)

  return(
    <>
      <div className="column is-3">
        <div className="grid-item">
          <div className={`${user.disabled && 'is-disabled'}`}>
            <div className={`h-avatar is-big ${!user.disabled &&'has-dot'}`}>
              <img className="avatar" src={user.photoURL || require('assets/img/profile-avatar-placeholder.png')}
                   alt=".." />
            </div>
            <h6 className="dark-inverted"><span className="text-primary">Name: </span>{user.displayName}</h6>
            <h6 className="dark-inverted"><span className="text-primary">Email: </span>{user.email}</h6>
            <h6 className="dark-inverted">{user.role}</h6>
            {user.role === 'client' && <h6 className="text-success"><span className="text-primary">
            abbrev: </span>{user['abbreviation']}
            </h6>}
            {user.disabled && <h6 className="text-danger">DISABLED</h6>}
          </div>
          <div className="buttons">
            <button className="button h-button is-dark-outlined width-100 is-flex-grow-3" onClick={() => setUpdateIsOpen(true)}>
              <span className="icon">
                <FeatherIcon icon="edit" />
              </span>
              <span>Edit</span>
            </button>
          </div>
        </div>
      </div>
      {updateIsOpen && <UpdateComponent setIsOpen={setUpdateIsOpen} user={user}/>}
    </>
  )
}
