import notificationConverter from 'converters/notificationConverter';

// noinspection ES6CheckImport
import {collection, query, onSnapshot, limit, orderBy, writeBatch, getDocs, doc} from "firebase/firestore";
import { createSlice } from '@reduxjs/toolkit'
import {firestore} from "firebase.js";


// =========================== Slice ===========================
export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
  },
  reducers: {
    set: (state, action) => {
      state.notifications = action.payload
    }
  },
})

// ============================= Actions =============================
export const { set } = notificationSlice.actions;

// ============================= Thunks =============================
export const fetchNotifications = (userID, count = 10) => async dispatch => {
  const q = query(collection(firestore, `users/${userID}/notifications`).withConverter(notificationConverter),
    orderBy('created_at', 'desc'), limit(count))
  onSnapshot(q, (querySnapshot) => {
    const notifications = [];
    querySnapshot.forEach((doc) => {
      notifications.push({...doc.data(), ...{id: doc.id}})
    });
    dispatch(set(notifications))
  })
};

export const clearNotifications = (userID) => async () => {
  const batch = writeBatch(firestore)
  const q = query(collection(firestore, `users/${userID}/notifications`))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((notification) => {
    batch.delete(doc(firestore, `users/${userID}/notifications/${notification.id}`))
  })
  await batch.commit()
}

// ============================ Selectors ============================
export const selectNotifications = state => state.notification.notifications;


// =========================== Default Export =========================
export default notificationSlice.reducer
