import {deleteDocument, update} from 'API';
import planConverter from 'converters/planConverter';
import {doc} from "firebase/firestore";
import {useButtonListener} from 'hooks/utils';
import moment from 'moment';
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useFirestore} from "reactfire";
import swal from 'sweetalert';
import PlanForm from 'views/plans/PlanForm';

export default function EditPlan({toggleIsOpen, plan}) {

  // Hooks
  const firestore = useFirestore()

  const { register, handleSubmit, reset, control, formState } = useForm({
    defaultValues: {...plan, ...{
        start: moment(plan.start).format(moment.HTML5_FMT.DATETIME_LOCAL),
        end: moment(plan.end).format(moment.HTML5_FMT.DATETIME_LOCAL)
      }}
  });
  const { isDirty } = formState;

  // States
  const [isLoading, setIsLoading] = useState(false)

  // Methods
  async function onSubmit(data) {
    try {
      setIsLoading(true)
      await update(doc(firestore, "plans", plan.id), planConverter.toFirestore(data))
      setIsLoading(false)
      reset();
      return toggleIsOpen(false)
    } catch (err) {
      setIsLoading(false)
      await swal('Warning', err.message, 'warning')
    }
  }

  function handleCancel(e) {
    e?.preventDefault()
    if(isDirty && !confirm('Are you sure you want to cancel the edits you have made?'))
      return;
    reset();
    toggleIsOpen(false)
  }

  const handleDelete = async (ev) => {
    ev.preventDefault()
    if(!confirm('Are you sure you want to delete this plan?'))
      return;
    await deleteDocument(doc(firestore, "plans", plan.id))
    toggleIsOpen(false)
  }

  useButtonListener(handleCancel, 27, [formState])

  return(
    <div className='modal h-modal is-big is-active'>
      <div className="modal-background h-modal-close"/>
      <div className="modal-content">
        <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
          <header className="modal-card-head">
            <h3>Edit Plan</h3>
            <a href="#" className="h-modal-close ml-auto" aria-label="close" onClick={() => toggleIsOpen()}>
              <i className="bi bi-x"/>
            </a>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <div className="tabs-wrapper">

                <div className={`tab-content is-active`}>
                  <PlanForm register={register} control={control} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-card-foot is-flex is-justify-content-space-between">
            <div>
              <button className={`button h-button is-danger ${isLoading && 'is-loading'}`} onClick={handleDelete}>
                Delete
              </button>
            </div>
            <div>
              <button className={`button h-button h-modal-close ${isLoading && 'is-loading'}`} onClick={handleCancel}>
                Cancel
              </button>
              <button className={`button h-button is-primary ${isLoading && 'is-loading'}`} type="submit">
                <span>Edit</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
