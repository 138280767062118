import Input from "components/Input";
import MultiSelectPicker from 'components/MultiSelectPicker';
import FeatherIcon from "feather-icons-react";
import {useButtonListener} from 'hooks/utils';
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from 'react-redux';
import {useFunctions} from "reactfire";
import {httpsCallable} from "firebase/functions";
import {fetchUsersByRole, selectUsersByRole} from 'slices/userSlice';
import swal from "sweetalert";

export default function CreateTeamLeader({setIsOpen}) {
  const functions = useFunctions()
  const createUser = httpsCallable(functions, 'usersFunctions-create')
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUsersByRole('client'))
    dispatch(fetchUsersByRole('member'))
  }, [])

  const clients = useSelector(selectUsersByRole('client'))
  const members = useSelector(selectUsersByRole('member'))


  const { register, handleSubmit, control, reset, formState } = useForm({
    defaultValues: {
      role: 'team_leader'
    }
  })
  const { isDirty } = formState;

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      await createUser(data)
      setIsLoading(false)
      await swal('Success', `Team Leader created successfully`, 'success')
      reset()
      setIsOpen(false)
    } catch (err) {
      setIsLoading(false)
      await swal('Warning', err.message, 'warning')
    }
  }

  const handleCancel = (e) => {
    e?.preventDefault()
    if(isDirty && !confirm('Are you sure you want to cancel the edits you have made?')) {
      return;
    }
    reset();
    setIsOpen(false)
  }

  useButtonListener(handleCancel, 27, [formState])

  return(
    <div className={`modal h-modal is-big is-active`}>
      <div className="modal-background h-modal-close"/>
      <div className="modal-content">
        <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
          <header className="modal-card-head">
            <h3>Add Team Leader</h3>
            <a href="#" className="h-modal-close ml-auto" aria-label="close" onClick={()=> setIsOpen(false)}>
              <FeatherIcon icon="x" />
            </a>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <div className="modal-form">
                <div className="columns is-multiline">
                  <Input label="Email" placeHolder="john@example.com" name="email" helper="Mandatory"
                         helperClass="help text-danger" register={register} validation={{required: true}}
                         options={{required: true}} />
                  <Input label="Display Name" placeHolder="John Doe" name="displayName" helper="Mandatory"
                         helperClass="help text-danger" register={register} validation={{required: true}}
                         options={{required: true}} />
                  <Input label="Password" placeHolder="Insert password here" name="password" helper="Mandatory"
                         helperClass="help text-danger" register={register} validation={{required: true}}
                         options={{required: true}} />

                  <div className="column is-6">
                    <div className="field">
                      <label>Clients</label>
                      <div className="control">
                        <MultiSelectPicker
                          control={control} name="clients"
                          options={clients.map(client => ({label: client.displayName, value: client.id}))}
                          args={{validation: {required: true}}}
                        />
                      </div>
                      <p className="help text-danger">Mandatory</p>
                    </div>
                  </div>

                  <div className="column is-6">
                    <div className="field">
                      <label>Members</label>
                      <div className="control">
                        <MultiSelectPicker
                          control={control} name="members" selectArgs={{menuPlacement: 'top'}}
                          options={members.map(member => ({label: member.displayName, value: member.id}))}
                          args={{validation: {required: true}}}
                        />
                      </div>
                      <p className="help text-danger">Mandatory</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="modal-card-foot is-end">
            <a className={`button h-button is-rounded h-modal-close ${isLoading && 'is-loading'}`}
               onClick={handleCancel} type="reset">
              Cancel
            </a>
            <button className={`button h-button is-primary is-raised is-rounded ${isLoading && 'is-loading'}`}>
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
