import {useEffect, useState} from "react";
import {useStorage} from "reactfire";
import {ref, getDownloadURL} from "firebase/storage";


export default function getProfileImage(uid) {

  const storage = useStorage()
  const [profileImg, setProfileImage] = useState('')


  useEffect(() => {
    getDownloadURL(ref(storage, `profile_images/${uid}.jpg`))
      .then( (url) => {
        setProfileImage(url || require('assets/img/profile-avatar-placeholder.png'))
      })
  }, [uid])

  return profileImg;
}
