// noinspection ES6CheckImport
import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { AuthProvider, FirestoreProvider, StorageProvider, FunctionsProvider} from "reactfire";

// Layouts
import MainLayout from 'layouts/MainLayout';
import AuthLayout from 'layouts/AuthLayout';

// Routes
import PrivateRoute from 'routes/PrivateRoute';
import AuthRoute from 'routes/AuthRoute';

// Contexts
import { AuthProvider as AppAuthProvider } from "contexts/AuthContext";
import { LayoutProvider } from "contexts/LayoutContext";
import { NotificationProvider } from "contexts/NotificationsContext";

import {firestore, auth, storage, functions} from "firebase.js"
import constants from 'constants.js';

// ============================ Assets ============================
import("assets/scss/main.scss")
import("assets/scss/custom.scss")
import("assets/css/main.css")
import("bootstrap-icons/font/bootstrap-icons.css")
import("react-datepicker/dist/react-datepicker.css")

export default function App() {

  return(
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestore}>
        <StorageProvider sdk={storage}>
          <FunctionsProvider sdk={functions}>
            <LayoutProvider>
              <AppAuthProvider>
                <NotificationProvider>
                  <Router>
                    <Switch>
                      <AuthRoute path={`/${constants.AUTH}`} component={AuthLayout} />
                      <PrivateRoute path={'/'} component={MainLayout} />
                      <Redirect from="*" to="/" />
                    </Switch>
                  </Router>
                </NotificationProvider>
              </AppAuthProvider>
            </LayoutProvider>
          </FunctionsProvider>
        </StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  )
}
