// noinspection ES6CheckImport

import {useAuth} from 'contexts/AuthContext';
import React, {useContext, useEffect} from 'react'
import {store} from "react-notifications-component";
import { getMessaging, onMessage } from "firebase/messaging";
import {useDispatch} from 'react-redux';
import {fetchNotifications} from 'slices/notificationSlice';

const NotificationContext = React.createContext(undefined)

export function useNotification() {
  return useContext(NotificationContext)
}


export function NotificationProvider({ children }) {

  const dispatch = useDispatch()
  const messaging = getMessaging()
  const { currentUser } = useAuth()

  useEffect(() => {
    if(currentUser) dispatch(fetchNotifications(currentUser.uid))
  }, [currentUser])

  useEffect(() => {
    onMessage(messaging, (payload) => {
      // TODO: turn on the click action
      store.addNotification({
        title: payload.notification.title,
        message: payload.notification.body,
        type: "default",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        // TODO: add this later
        // onRemoval: (id, removedBy) => {
        // if(removedBy === 'click') {
        //   history.push(`/${history.location.pathname.split('/')[1]}/notifications`)
        // }
        // },
        dismiss: {
          duration: 5000,
          pauseOnHover: true
        }
      });
    });
  }, [])

  const value = {
  }

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}
