import TextareaAutosize from 'react-textarea-autosize'

export default function Input({register, size= 6, label, type = 'text', placeHolder,
                                name = 'placeholder', validation= {}, helper, helperClass= 'help',
                                options}) {

  function renderInputTag() {
    if(type === 'textarea') {
      return (<TextareaAutosize id={`${name}-input`} className="textarea is-primary-focus" placeholder={placeHolder} dir="auto"
                        {...options} {...register(name, validation)} />)
    } else {
      return (<input id={`${name}-input`} type={type} className="input is-primary-focus" placeholder={placeHolder} dir="auto"
                     {...options} {...register(name, validation)} />)
    }
  }


  return(
    <div className={`column is-${size}`}>
      <div className="field">
        <label htmlFor={`${name}-input`}>{label}</label>
        <div className="control">
          {renderInputTag()}
        </div>
        <p className={helperClass}>{helper}</p>
      </div>
    </div>
  )
}
