import Select from "react-select";
import {Controller} from "react-hook-form";

import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();
import './custom.scss';
import {useLayout} from "contexts/LayoutContext";

export default function SingleSelectPicker({control, options = [], name, defaultValue = null,
                                             className = 'select-input', args = {}, selectArgs = {},
                                             menuPlacement= 'auto'}) {

  const {isLightTheme} = useLayout();

  return(
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      {...args}
      render={({ field: { onChange, value,  ref }}) => (
        <Select
          menuPlacement={menuPlacement}
          inputRef={ref}
          value={options.find(op => op.value === value)}
          onChange={item => onChange(item.value)}
          options={options}
          maxMenuHeight={'15vh'}
          components={animatedComponents}
          className={className}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: isLightTheme() ? '#1c6ac9' : '#797bf2',
              primary75: isLightTheme() ? '#1c6ac9' : '#797bf2',
              primary50: isLightTheme() ? '#1c6ac9' : '#797bf2',
              primary25: isLightTheme() ? '#a9abac' : '#797bf2',
              neutral0: isLightTheme() ? '#fff' : '#28282b',
              neutral5: isLightTheme() ? '#1c6ac9' : '#28282b',
              neutral10: isLightTheme() ? '#a9abac' : '#323236',
              neutral90: '#323236',
            },
          })}
          {...selectArgs}
        />
      )}
    />
  )
}
