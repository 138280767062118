import Model from "models/Model.js";
import Joi from "joi";

export default class Client extends Model {

  data = {}

  // noinspection JSCheckFunctionSignatures
  schema = Joi.object().keys({
    displayName: Joi.string().min(3).max(30).allow('')
      .messages({
        'string.min': "Display name must be at least 3 characters long",
        'string.max': "Display name must be 30 characters long maximum"
      }),
    password: Joi.string().min(8).max(128).allow(''),
    confirm_password: Joi.string().equal(Joi.ref('password'))
      .required()
      .messages({ 'any.only': 'Passwords does not match' })
  })


  constructor(data) {
    super();
    this.data = {...this.data, ...data};
    return this;
  }
}
