import AdditionalFieldsInput from 'components/AdditionalFieldsInput';
import CreatableMultiSelectPicker from "components/CreatableMultiSelectPicker";
import Input from "components/Input";
import SingleSelectPicker from 'components/SingleSelectPicker';
import countries from 'data/countries';
import constants from 'constants.js'
import FeatherIcon from "feather-icons-react";
import {httpsCallable} from "firebase/functions";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useFunctions} from "reactfire";
import swal from "sweetalert";

export default function UpdateClient({setIsOpen, user}) {
  const functions = useFunctions()
  const updateUser = httpsCallable(functions, 'usersFunctions-update')
  const [isLoading, setIsLoading] = useState(false)

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: user
  })

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      await updateUser(data)
      setIsLoading(false)
      await swal('Success', `Client updated successfully`, 'success')
      reset()
      setIsOpen(false)
    } catch (err) {
      setIsLoading(false)
      await swal('Warning', err.message, 'warning')
    }
  }


  return(
    <div className={`modal h-modal is-big is-active`}>
      <div className="modal-background h-modal-close"/>
      <div className="modal-content">
        <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
          <header className="modal-card-head">
            <h3>Update Client</h3>
            <button className="h-modal-close ml-auto" aria-label="close" onClick={()=> setIsOpen(false)}>
              <FeatherIcon icon="x" />
            </button>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <div className="modal-form">
                <div className="columns is-multiline">
                  <Input label="Display Name" placeHolder="John Doe" name="displayName" helper="Mandatory"
                         helperClass="help text-danger" register={register} validation={{required: true}}
                         options={{required: true, defaultValue: user.displayName}} />
                  {user.role === 'client' &&
                    <Input label="Name Abbreviation" placeHolder="Ex: JOE" name="abbreviation" helper="Mandatory"
                           helperClass="help text-danger" register={register} validation={{required: true}}
                           options={{required: true, defaultValue: user['abbreviation']}}/>}

                  {user.role === 'client' &&
                    <div className="column is-11">
                      <div className="field">
                        <label>Design Sizes</label>
                        <div className="control">
                          <CreatableMultiSelectPicker
                            name="design_sizes" dropdownDisabled={true} register={register} control={control}
                            defaultValue={user.design_sizes || []} args={{validation: {required: true}}}/>
                        </div>
                        <p className="help text-info">Press enter after each design size</p>
                        <p className="help text-danger">Mandatory</p>
                      </div>
                    </div>}
                  <div className="column is-1 ml-auto">
                    <div className="field">
                      <label>Disabled</label>
                      <div className="control">
                        <label className="form-switch is-primary">
                          <input type="checkbox" className="is-switch" defaultChecked={user.disabled}
                                 {...register('disabled')}/>
                          <i/>
                        </label>
                      </div>
                      <p className="help">Optional</p>
                    </div>
                  </div>

                  <div className="column is-6">
                    <div className="field">
                      <label>Country</label>
                      <div className="control">
                        <SingleSelectPicker
                          options={countries} name="country" register={register}
                          control={control} menuPlacement={'top'} />
                      </div>
                      <p className="help">Optional</p>
                    </div>
                  </div>

                  <div className="column is-6">
                    <div className="field">
                      <label>Category</label>
                      <div className="control">
                        <SingleSelectPicker
                          options={constants.CLIENTS_CATEGORIES.map(category => ({label: category, value: category}))}
                          name="category" register={register} control={control} menuPlacement={'top'}
                          args={{rules: {required: true}}} />
                      </div>
                      <p className="help text-danger">Mandatory</p>
                    </div>
                  </div>


                  <AdditionalFieldsInput
                    name={'metadata'} control={control} size={12} defaultValue={user.metadata} />

                </div>
              </div>
            </div>
          </div>
          <div className="modal-card-foot is-end">
            <a className={`button h-button is-rounded h-modal-close ${isLoading && 'is-loading'}`}
               onClick={()=> {reset(); setIsOpen(false)}} type="reset">
              Cancel
            </a>
            <button className={`button h-button is-primary is-raised is-rounded ${isLoading && 'is-loading'}`}>
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
