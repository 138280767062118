import { configureStore } from '@reduxjs/toolkit'
import taskReducer from "slices/taskSlice";
import signUpRequestReducer from "slices/signUpRequestSlice";
import inquiryReducer from "slices/inquirySlice";
import userReducer from "slices/userSlice";
import notificationReducer from 'slices/notificationSlice';
import planReducer from 'slices/planSlice';

export default configureStore({
  reducer: {
    task: taskReducer,
    signUpRequest: signUpRequestReducer,
    inquiry: inquiryReducer,
    user: userReducer,
    notification: notificationReducer,
    plan: planReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['task/set','task/setFavorite', 'task/filterByMonth', 'task/filterByYear',
          'task/filterByStatus', 'signUpRequest/set', 'inquiry/set', 'user/set_clients', 'user/set_members',
          'user/set_team_leaders', 'notification/set', 'plan/set'],
        // Ignore these field paths in all actions
        ignoredActionPaths: [],
        // Ignore these paths in the state
        ignoredPaths: [],
      },
    }),
})
