import FeatherIcon from 'feather-icons-react';
import useDropdown from 'hooks/useDropdown';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchUsersByRole, searchUsers, selectUsersByRole} from "slices/userSlice";
import CreateUser from 'views/users/CreateClient';
import CreateMember from 'views/users/CreateMember';
import CreateTeamLeader from 'views/users/CreateTeamLeader';
import UserCard from "views/users/UserCard";
import UpdateClient from 'views/users/UpdateClient';
//=================================================

export default function Index({role, UpdateComponent = UpdateClient}) {
  const [clientFormIsOpen, setClientFormIsOpen] = useState(false)
  const [memberFormIsOpen, setMemberFormIsOpen] = useState(false)
  const [teamLeaderFormIsOpen, setTeamLeaderFormIsOpen] = useState(false)
  const [users, setUsers] = useState([])
  const [dropdownIsOpen, toggleDropdown] = useDropdown()
  const [searchTerm, setSearchTerm] = useState('')
  const usersPrototype = useSelector(selectUsersByRole(role))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUsersByRole(role))
  }, [])

  useEffect(() => {
    const fetchUsers = async () => {
      return await dispatch(searchUsers(searchTerm, role))
    }
    fetchUsers()
      .then(fetchedUsers => {
        setUsers(fetchedUsers)
      })
  }, [usersPrototype, searchTerm])


  return(
    <>
      {clientFormIsOpen && <CreateUser setIsOpen={setClientFormIsOpen}/>}
      {memberFormIsOpen && <CreateMember setIsOpen={setMemberFormIsOpen}/>}
      {teamLeaderFormIsOpen && <CreateTeamLeader setIsOpen={setTeamLeaderFormIsOpen}/>}
      <div className="user-grid-toolbar">
        <div className="control has-icon">
          <input className="input custom-text-filter" placeholder="Search..."
                 onChange={(ev) => setSearchTerm(ev.currentTarget.value)} />
          <div className="form-icon">
            <FeatherIcon icon="search"/>
          </div>
        </div>

        <div className="buttons">
          <div className={`dropdown is-modern is-spaced dropdown-trigger ${dropdownIsOpen && 'is-active'}`}>
            <div className="is-trigger" aria-haspopup="true" aria-controls="dropdown-menu">
              <button className="button h-button is-primary is-raised" aria-haspopup="true" onClick={toggleDropdown}
                      aria-controls="dropdown-menu">
                <span>Add User</span>
                <FeatherIcon icon="chevron-down" />
              </button>
            </div>
            <div className="dropdown-menu" role="menu">
              <div className="dropdown-content">
                <a href="#" className="dropdown-item is-media" onClick={() => setClientFormIsOpen(true)}>
                  <div className="icon">
                    <i className="lnil lnil-suitcase"/>
                  </div>
                  <div className="meta">
                    <span>Client</span>
                    <span>Add a client to Grafix Shop</span>
                  </div>
                </a>
                <a href="#" className="dropdown-item is-media" onClick={() => setMemberFormIsOpen(true)}>
                  <div className="icon">
                    <i className="lnil lnil-super-happy"/>
                  </div>
                  <div className="meta">
                    <span>Member</span>
                    <span>Add a member to Grafix Shop</span>
                  </div>
                </a>
                <a href="#" className="dropdown-item is-media" onClick={() => setTeamLeaderFormIsOpen(true)}>
                  <div className="icon">
                    <i className="lnil lnil-users-alt"/>
                  </div>
                  <div className="meta">
                    <span>Team Leader</span>
                    <span>Add a team leader to Grafix Shop</span>
                  </div>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="page-content-inner">
        <div className="user-grid user-grid-v1">
          <div className="page-placeholder custom-text-filter-placeholder is-hidden">
            <div className="placeholder-content">
              <img className="light-image" src={require("assets/img/illustrations/placeholders/search-4.svg").default} alt="" />
              <img className="dark-image" src={require("assets/img/illustrations/placeholders/search-4-dark.svg").default} alt=""/>
              <h3>We couldn't find any matching results.</h3>
              <p className="is-larger">Too bad. Looks like we couldn't find any matching results for the
                search terms you've entered. Please try different search terms or criteria.</p>
            </div>
          </div>

          <div className="columns is-multiline">

            {users?.map((user, index) => <UserCard key={index} user={user} UpdateComponent={UpdateComponent} />)}

          </div>
        </div>
      </div>
    </>
  )
}
