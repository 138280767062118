const constants = {
  CLIENT: "client",
  ADMIN: "admin",
  MEMBER: "member",
  AUTH: "auth",
  PUBLIC: "public",
  GRAFIX_SHOP: "Grafix Shop",
  THEME: "theme",
  LIGHT: "light",
  DARK: "dark",
  themeColors: {
    primary: '#1c6ac9',
    primaryMedium: '#d4b3ff',
    primaryLight: '#f4edfd',
    secondary: '#ff227d',
    accent: '#797bf2',
    success: '#06d6a0',
    info: '#039BE5',
    warning: '#faae42',
    danger: '#FF7273',
    purple: '#8269B2',
    blue: '#37C3FF',
    green: '#93E088',
    yellow: '#FFD66E',
    orange: '#FFA981',
    lightText: '#a2a5b9',
    fadeGrey: '#ededed',
  },
  MONTHS: ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'],
  CLIENTS_CATEGORIES: ['Government', 'Health & Medicine', 'Food & Beverage', 'Education & Training',
    'Marketing & Sales', 'Charity & Voluntary', 'Real Estate', 'Small Business', 'Home Business', 'Individuals',
    'Online Services', 'Nutrition & Fitness', 'Tech', 'Other']
};
export default constants;
