import {useLayout} from "contexts/LayoutContext";
import React from 'react';
import {Controller} from "react-hook-form";
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

import CreatableSelect from 'react-select/creatable';

export default function CreatableMultiSelectPicker({name, dropdownDisabled = false, isMulti = true,
                                                     options = [], control, args, defaultValue = [], className = ''}) {

  const {isLightTheme} = useLayout()


  return (
    <>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        {...args}
        render={({ field: { onChange, ref }}) => (
          <CreatableSelect
            defaultValue={defaultValue?.map(item => ({label: item, value: item}))}
            inputRef={ref}
            onChange={val => onChange(val.map(c => c.value))}
            options={options}
            isMulti={isMulti}
            maxMenuHeight={'15vh'}
            className={className}
            components={dropdownDisabled ? {
              Menu:()=>(<></>),
              IndicatorsContainer:()=>(<></>)
            } : animatedComponents}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: isLightTheme() ? '#1c6ac9' : '#797bf2',
                primary75: isLightTheme() ? '#1c6ac9' : '#797bf2',
                primary50: isLightTheme() ? '#1c6ac9' : '#797bf2',
                primary25: isLightTheme() ? '#a9abac' : '#797bf2',
                neutral5: isLightTheme() ? '#1c6ac9' : '#28282b',
                neutral90: '#323236'
              },
            })}
          />
        )}
      />
    </>
  );
}
