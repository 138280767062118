import Joi from "joi";

export default class Model {

  // The data of the model
  data = {
    id: ''
  }

  // validation rule
  schema = Joi.object({
    id: Joi.string()
  })

  // Validates the model
  validate() {
    const {error} = this.schema.validate(this.data);
    if(error) {
      throw new Error(error)
    }
  }
}
