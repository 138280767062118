// noinspection ES6CheckImport
import {collection, query, onSnapshot} from "firebase/firestore";
import { createSlice } from '@reduxjs/toolkit'
import {firestore} from "firebase.js";


// =========================== Slice ===========================
export const signUpRequestSlice = createSlice({
  name: 'signUpRequest',
  initialState: {
    signUpRequests: [],
  },
  reducers: {
    set: (state, action) => {
      state.signUpRequests = action.payload
    }
  },
})

// ============================= Actions =============================
export const { set } = signUpRequestSlice.actions

// ============================= Thunks =============================
export const fetchAllSignUpRequests = () => async dispatch => {
  const q = query(collection(firestore, 'signup_requests'))
  onSnapshot(q, (querySnapshot) => {
    const signUpRequests = [];
    querySnapshot.forEach((doc) => {
      signUpRequests.push({...doc.data(), ...{id: doc.id}})
    });
    dispatch(set(signUpRequests))
  })
};

// ============================ Selectors ============================
export const selectSignUpRequests = state => state.signUpRequest.signUpRequests;


// =========================== Default Export =========================
export default signUpRequestSlice.reducer
