const notificationConverter = {
  toFirestore: (data) => {
    return data;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return {
      ...data, ...{
        created_at: data.created_at?.toDate()
      }
    };
  }
}

export default notificationConverter;
