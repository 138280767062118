export default [
    {"value": "AF", "label": "Afghanistan", "number": "004"},
    {"value": "AL", "label": "Albania", "number": "008"},
    {"value": "DZ", "label": "Algeria", "number": "012"},
    {"value": "AS", "label": "American Samoa", "number": "016"},
    {"value": "AD", "label": "Andorra", "number": "020"},
    {"value": "AO", "label": "Angola", "number": "024"},
    {"value": "AI", "label": "Anguilla", "number": "660"},
    {"value": "AQ", "label": "Antarctica", "number": "010"},
    {"value": "AG", "label": "Antigua and Barbuda", "number": "028"},
    {"value": "AR", "label": "Argentina", "number": "032"},
    {"value": "AM", "label": "Armenia", "number": "051"},
    {"value": "AW", "label": "Aruba", "number": "533"},
    {"value": "AU", "label": "Australia", "number": "036"},
    {"value": "AT", "label": "Austria", "number": "040"},
    {"value": "AZ", "label": "Azerbaijan", "number": "031"},
    {"value": "BS", "label": "Bahamas (the)", "number": "044"},
    {"value": "BH", "label": "Bahrain", "number": "048"},
    {"value": "BD", "label": "Bangladesh", "number": "050"},
    {"value": "BB", "label": "Barbados", "number": "052"},
    {"value": "BY", "label": "Belarus", "number": "112"},
    {"value": "BE", "label": "Belgium", "number": "056"},
    {"value": "BZ", "label": "Belize", "number": "084"},
    {"value": "BJ", "label": "Benin", "number": "204"},
    {"value": "BM", "label": "Bermuda", "number": "060"},
    {"value": "BT", "label": "Bhutan", "number": "064"},
    {"value": "BO", "label": "Bolivia (Plurinational State of)", "number": "068"},
    {"value": "BQ", "label": "Bonaire, Sint Eustatius and Saba", "number": "535"},
    {"value": "BA", "label": "Bosnia and Herzegovina", "number": "070"},
    {"value": "BW", "label": "Botswana", "number": "072"},
    {"value": "BV", "label": "Bouvet Island", "number": "074"},
    {"value": "BR", "label": "Brazil", "number": "076"},
    {"value": "IO", "label": "British Indian Ocean Territory (the)", "number": "086"},
    {"value": "BN", "label": "Brunei Darussalam", "number": "096"},
    {"value": "BG", "label": "Bulgaria", "number": "100"},
    {"value": "BF", "label": "Burkina Faso", "number": "854"},
    {"value": "BI", "label": "Burundi", "number": "108"},
    {"value": "CV", "label": "Cabo Verde", "number": "132"},
    {"value": "KH", "label": "Cambodia", "number": "116"},
    {"value": "CM", "label": "Cameroon", "number": "120"},
    {"value": "CA", "label": "Canada", "number": "124"},
    {"value": "KY", "label": "Cayman Islands (the)", "number": "136"},
    {"value": "CF", "label": "Central African Republic (the)", "number": "140"},
    {"value": "TD", "label": "Chad", "number": "148"},
    {"value": "CL", "label": "Chile", "number": "152"},
    {"value": "CN", "label": "China", "number": "156"},
    {"value": "CX", "label": "Christmas Island", "number": "162"},
    {"value": "CC", "label": "Cocos (Keeling) Islands (the)", "number": "166"},
    {"value": "CO", "label": "Colombia", "number": "170"},
    {"value": "KM", "label": "Comoros (the)", "number": "174"},
    {"value": "CD", "label": "Congo (the Democratic Republic of the)", "number": "180"},
    {"value": "CG", "label": "Congo (the)", "number": "178"},
    {"value": "CK", "label": "Cook Islands (the)", "number": "184"},
    {"value": "CR", "label": "Costa Rica", "number": "188"},
    {"value": "HR", "label": "Croatia", "number": "191"},
    {"value": "CU", "label": "Cuba", "number": "192"},
    {"value": "CW", "label": "Curaçao", "number": "531"},
    {"value": "CY", "label": "Cyprus", "number": "196"},
    {"value": "CZ", "label": "Czechia", "number": "203"},
    {"value": "CI", "label": "Côte d'Ivoire", "number": "384"},
    {"value": "DK", "label": "Denmark", "number": "208"},
    {"value": "DJ", "label": "Djibouti", "number": "262"},
    {"value": "DM", "label": "Dominica", "number": "212"},
    {"value": "DO", "label": "Dominican Republic (the)", "number": "214"},
    {"value": "EC", "label": "Ecuador", "number": "218"},
    {"value": "EG", "label": "Egypt", "number": "818"},
    {"value": "SV", "label": "El Salvador", "number": "222"},
    {"value": "GQ", "label": "Equatorial Guinea", "number": "226"},
    {"value": "ER", "label": "Eritrea", "number": "232"},
    {"value": "EE", "label": "Estonia", "number": "233"},
    {"value": "SZ", "label": "Eswatini", "number": "748"},
    {"value": "ET", "label": "Ethiopia", "number": "231"},
    {"value": "FK", "label": "Falkland Islands (the) [Malvinas]", "number": "238"},
    {"value": "FO", "label": "Faroe Islands (the)", "number": "234"},
    {"value": "FJ", "label": "Fiji", "number": "242"},
    {"value": "FI", "label": "Finland", "number": "246"},
    {"value": "FR", "label": "France", "number": "250"},
    {"value": "GF", "label": "French Guiana", "number": "254"},
    {"value": "PF", "label": "French Polynesia", "number": "258"},
    {"value": "TF", "label": "French Southern Territories (the)", "number": "260"},
    {"value": "GA", "label": "Gabon", "number": "266"},
    {"value": "GM", "label": "Gambia (the)", "number": "270"},
    {"value": "GE", "label": "Georgia", "number": "268"},
    {"value": "DE", "label": "Germany", "number": "276"},
    {"value": "GH", "label": "Ghana", "number": "288"},
    {"value": "GI", "label": "Gibraltar", "number": "292"},
    {"value": "GR", "label": "Greece", "number": "300"},
    {"value": "GL", "label": "Greenland", "number": "304"},
    {"value": "GD", "label": "Grenada", "number": "308"},
    {"value": "GP", "label": "Guadeloupe", "number": "312"},
    {"value": "GU", "label": "Guam", "number": "316"},
    {"value": "GT", "label": "Guatemala", "number": "320"},
    {"value": "GG", "label": "Guernsey", "number": "831"},
    {"value": "GN", "label": "Guinea", "number": "324"},
    {"value": "GW", "label": "Guinea-Bissau", "number": "624"},
    {"value": "GY", "label": "Guyana", "number": "328"},
    {"value": "HT", "label": "Haiti", "number": "332"},
    {"value": "HM", "label": "Heard Island and McDonald Islands", "number": "334"},
    {"value": "VA", "label": "Holy See (the)", "number": "336"},
    {"value": "HN", "label": "Honduras", "number": "340"},
    {"value": "HK", "label": "Hong Kong", "number": "344"},
    {"value": "HU", "label": "Hungary", "number": "348"},
    {"value": "IS", "label": "Iceland", "number": "352"},
    {"value": "IN", "label": "India", "number": "356"},
    {"value": "ID", "label": "Indonesia", "number": "360"},
    {"value": "IR", "label": "Iran (Islamic Republic of)", "number": "364"},
    {"value": "IQ", "label": "Iraq", "number": "368"},
    {"value": "IE", "label": "Ireland", "number": "372"},
    {"value": "IM", "label": "Isle of Man", "number": "833"},
    {"value": "IL", "label": "Israel", "number": "376"},
    {"value": "IT", "label": "Italy", "number": "380"},
    {"value": "JM", "label": "Jamaica", "number": "388"},
    {"value": "JP", "label": "Japan", "number": "392"},
    {"value": "JE", "label": "Jersey", "number": "832"},
    {"value": "JO", "label": "Jordan", "number": "400"},
    {"value": "KZ", "label": "Kazakhstan", "number": "398"},
    {"value": "KE", "label": "Kenya", "number": "404"},
    {"value": "KI", "label": "Kiribati", "number": "296"},
    {"value": "KP", "label": "Korea (the Democratic People's Republic of)", "number": "408"},
    {"value": "KR", "label": "Korea (the Republic of)", "number": "410"},
    {"value": "KW", "label": "Kuwait", "number": "414"},
    {"value": "KG", "label": "Kyrgyzstan", "number": "417"},
    {"value": "LA", "label": "Lao People's Democratic Republic (the)", "number": "418"},
    {"value": "LV", "label": "Latvia", "number": "428"},
    {"value": "LB", "label": "Lebanon", "number": "422"},
    {"value": "LS", "label": "Lesotho", "number": "426"},
    {"value": "LR", "label": "Liberia", "number": "430"},
    {"value": "LY", "label": "Libya", "number": "434"},
    {"value": "LI", "label": "Liechtenstein", "number": "438"},
    {"value": "LT", "label": "Lithuania", "number": "440"},
    {"value": "LU", "label": "Luxembourg", "number": "442"},
    {"value": "MO", "label": "Macao", "number": "446"},
    {"value": "MG", "label": "Madagascar", "number": "450"},
    {"value": "MW", "label": "Malawi", "number": "454"},
    {"value": "MY", "label": "Malaysia", "number": "458"},
    {"value": "MV", "label": "Maldives", "number": "462"},
    {"value": "ML", "label": "Mali", "number": "466"},
    {"value": "MT", "label": "Malta", "number": "470"},
    {"value": "MH", "label": "Marshall Islands (the)", "number": "584"},
    {"value": "MQ", "label": "Martinique", "number": "474"},
    {"value": "MR", "label": "Mauritania", "number": "478"},
    {"value": "MU", "label": "Mauritius", "number": "480"},
    {"value": "YT", "label": "Mayotte", "number": "175"},
    {"value": "MX", "label": "Mexico", "number": "484"},
    {"value": "FM", "label": "Micronesia (Federated States of)", "number": "583"},
    {"value": "MD", "label": "Moldova (the Republic of)", "number": "498"},
    {"value": "MC", "label": "Monaco", "number": "492"},
    {"value": "MN", "label": "Mongolia", "number": "496"},
    {"value": "ME", "label": "Montenegro", "number": "499"},
    {"value": "MS", "label": "Montserrat", "number": "500"},
    {"value": "MA", "label": "Morocco", "number": "504"},
    {"value": "MZ", "label": "Mozambique", "number": "508"},
    {"value": "MM", "label": "Myanmar", "number": "104"},
    {"value": "NA", "label": "Namibia", "number": "516"},
    {"value": "NR", "label": "Nauru", "number": "520"},
    {"value": "NP", "label": "Nepal", "number": "524"},
    {"value": "NL", "label": "Netherlands (the)", "number": "528"},
    {"value": "NC", "label": "New Caledonia", "number": "540"},
    {"value": "NZ", "label": "New Zealand", "number": "554"},
    {"value": "NI", "label": "Nicaragua", "number": "558"},
    {"value": "NE", "label": "Niger (the)", "number": "562"},
    {"value": "NG", "label": "Nigeria", "number": "566"},
    {"value": "NU", "label": "Niue", "number": "570"},
    {"value": "NF", "label": "Norfolk Island", "number": "574"},
    {"value": "MP", "label": "Northern Mariana Islands (the)", "number": "580"},
    {"value": "NO", "label": "Norway", "number": "578"},
    {"value": "OM", "label": "Oman", "number": "512"},
    {"value": "PK", "label": "Pakistan", "number": "586"},
    {"value": "PW", "label": "Palau", "number": "585"},
    {"value": "PS", "label": "Palestine, State of", "number": "275"},
    {"value": "PA", "label": "Panama", "number": "591"},
    {"value": "PG", "label": "Papua New Guinea", "number": "598"},
    {"value": "PY", "label": "Paraguay", "number": "600"},
    {"value": "PE", "label": "Peru", "number": "604"},
    {"value": "PH", "label": "Philippines (the)", "number": "608"},
    {"value": "PN", "label": "Pitcairn", "number": "612"},
    {"value": "PL", "label": "Poland", "number": "616"},
    {"value": "PT", "label": "Portugal", "number": "620"},
    {"value": "PR", "label": "Puerto Rico", "number": "630"},
    {"value": "QA", "label": "Qatar", "number": "634"},
    {"value": "MK", "label": "Republic of North Macedonia", "number": "807"},
    {"value": "RO", "label": "Romania", "number": "642"},
    {"value": "RU", "label": "Russian Federation (the)", "number": "643"},
    {"value": "RW", "label": "Rwanda", "number": "646"},
    {"value": "RE", "label": "Réunion", "number": "638"},
    {"value": "BL", "label": "Saint Barthélemy", "number": "652"},
    {"value": "SH", "label": "Saint Helena, Ascension and Tristan da Cunha", "number": "654"},
    {"value": "KN", "label": "Saint Kitts and Nevis", "number": "659"},
    {"value": "LC", "label": "Saint Lucia", "number": "662"},
    {"value": "MF", "label": "Saint Martin (French part)", "number": "663"},
    {"value": "PM", "label": "Saint Pierre and Miquelon", "number": "666"},
    {"value": "VC", "label": "Saint Vincent and the Grenadines", "number": "670"},
    {"value": "WS", "label": "Samoa", "number": "882"},
    {"value": "SM", "label": "San Marino", "number": "674"},
    {"value": "ST", "label": "Sao Tome and Principe", "number": "678"},
    {"value": "SA", "label": "Saudi Arabia", "number": "682"},
    {"value": "SN", "label": "Senegal", "number": "686"},
    {"value": "RS", "label": "Serbia", "number": "688"},
    {"value": "SC", "label": "Seychelles", "number": "690"},
    {"value": "SL", "label": "Sierra Leone", "number": "694"},
    {"value": "SG", "label": "Singapore", "number": "702"},
    {"value": "SX", "label": "Sint Maarten (Dutch part)", "number": "534"},
    {"value": "SK", "label": "Slovakia", "number": "703"},
    {"value": "SI", "label": "Slovenia", "number": "705"},
    {"value": "SB", "label": "Solomon Islands", "number": "090"},
    {"value": "SO", "label": "Somalia", "number": "706"},
    {"value": "ZA", "label": "South Africa", "number": "710"},
    {"value": "GS", "label": "South Georgia and the South Sandwich Islands", "number": "239"},
    {"value": "SS", "label": "South Sudan", "number": "728"},
    {"value": "ES", "label": "Spain", "number": "724"},
    {"value": "LK", "label": "Sri Lanka", "number": "144"},
    {"value": "SD", "label": "Sudan (the)", "number": "729"},
    {"value": "SR", "label": "Surilabel", "number": "740"},
    {"value": "SJ", "label": "Svalbard and Jan Mayen", "number": "744"},
    {"value": "SE", "label": "Sweden", "number": "752"},
    {"value": "CH", "label": "Switzerland", "number": "756"},
    {"value": "SY", "label": "Syrian Arab Republic", "number": "760"},
    {"value": "TW", "label": "Taiwan", "number": "158"},
    {"value": "TJ", "label": "Tajikistan", "number": "762"},
    {"value": "TZ", "label": "Tanzania, United Republic of", "number": "834"},
    {"value": "TH", "label": "Thailand", "number": "764"},
    {"value": "TL", "label": "Timor-Leste", "number": "626"},
    {"value": "TG", "label": "Togo", "number": "768"},
    {"value": "TK", "label": "Tokelau", "number": "772"},
    {"value": "TO", "label": "Tonga", "number": "776"},
    {"value": "TT", "label": "Trinidad and Tobago", "number": "780"},
    {"value": "TN", "label": "Tunisia", "number": "788"},
    {"value": "TR", "label": "Turkey", "number": "792"},
    {"value": "TM", "label": "Turkmenistan", "number": "795"},
    {"value": "TC", "label": "Turks and Caicos Islands (the)", "number": "796"},
    {"value": "TV", "label": "Tuvalu", "number": "798"},
    {"value": "UG", "label": "Uganda", "number": "800"},
    {"value": "UA", "label": "Ukraine", "number": "804"},
    {"value": "AE", "label": "United Arab Emirates (the)", "number": "784"},
    {"value": "GB", "label": "United Kingdom of Great Britain and Northern Ireland (the)", "number": "826"},
    {"value": "UM", "label": "United States Minor Outlying Islands (the)", "number": "581"},
    {"value": "US", "label": "United States of America (the)", "number": "840"},
    {"value": "UY", "label": "Uruguay", "number": "858"},
    {"value": "UZ", "label": "Uzbekistan", "number": "860"},
    {"value": "VU", "label": "Vanuatu", "number": "548"},
    {"value": "VE", "label": "Venezuela (Bolivarian Republic of)", "number": "862"},
    {"value": "VN", "label": "Viet Nam", "number": "704"},
    {"value": "VG", "label": "Virgin Islands (British)", "number": "092"},
    {"value": "VI", "label": "Virgin Islands (U.S.)", "number": "850"},
    {"value": "WF", "label": "Wallis and Futuna", "number": "876"},
    {"value": "EH", "label": "Western Sahara", "number": "732"},
    {"value": "YE", "label": "Yemen", "number": "887"},
    {"value": "ZM", "label": "Zambia", "number": "894"},
    {"value": "ZW", "label": "Zimbabwe", "number": "716"},
    {"value": "AX", "label": "Åland Islands", "number": "248"}
];
