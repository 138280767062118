import Input from "components/Input";
import MultiSelectPicker from 'components/MultiSelectPicker';
import FeatherIcon from "feather-icons-react";
import {httpsCallable} from "firebase/functions";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from 'react-redux';
import {useFunctions} from "reactfire";
import {fetchUsersByRole, selectUsersByRole} from 'slices/userSlice';
import swal from "sweetalert";

export default function UpdateTeamLeader({setIsOpen, user}) {
  const functions = useFunctions()
  const updateUser = httpsCallable(functions, 'usersFunctions-update')
  const [isLoading, setIsLoading] = useState(false)

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: user
  })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUsersByRole('client'))
    dispatch(fetchUsersByRole('member'))
  }, [])

  const clients = useSelector(selectUsersByRole('client'))
  const members = useSelector(selectUsersByRole('member'))

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      await updateUser(data)
      setIsLoading(false)
      await swal('Success', `Team Leader updated successfully`, 'success')
      reset()
      setIsOpen(false)
    } catch (err) {
      setIsLoading(false)
      await swal('Warning', err.message, 'warning')
    }
  }


  return(
    <div className={`modal h-modal is-big is-active`}>
      <div className="modal-background h-modal-close"/>
      <div className="modal-content">
        <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
          <header className="modal-card-head">
            <h3>Update Client</h3>
            <button className="h-modal-close ml-auto" aria-label="close" onClick={()=> setIsOpen(false)}>
              <FeatherIcon icon="x" />
            </button>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <div className="modal-form">
                <div className="columns is-multiline">
                  <Input label="Display Name" placeHolder="John Doe" name="displayName" helper="Mandatory"
                         helperClass="help text-danger" register={register} validation={{required: true}}
                         options={{required: true, defaultValue: user.displayName}} />

                  <div className="column is-1 ml-auto">
                    <div className="field">
                      <label>Disabled</label>
                      <div className="control">
                        <label className="form-switch is-primary">
                          <input type="checkbox" className="is-switch" defaultChecked={user.disabled}
                                 {...register('disabled')}/>
                          <i/>
                        </label>
                      </div>
                      <p className="help">Optional</p>
                    </div>
                  </div>

                  <div className="column is-6">
                    <div className="field">
                      <label>Clients</label>
                      <div className="control">
                        <MultiSelectPicker
                          control={control} name="clients" selectArgs={{menuPlacement: 'top'}}
                          options={clients.map(client => ({label: client.displayName, value: client.id}))}
                          args={{validation: {required: true}}}
                        />
                      </div>
                      <p className="help text-danger">Mandatory</p>
                    </div>
                  </div>

                  <div className="column is-6">
                    <div className="field">
                      <label>Members</label>
                      <div className="control">
                        <MultiSelectPicker
                          control={control} name="members" selectArgs={{menuPlacement: 'top'}}
                          options={members.map(member => ({label: member.displayName, value: member.id}))}
                          args={{validation: {required: true}}}
                        />
                      </div>
                      <p className="help text-danger">Mandatory</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="modal-card-foot is-end">
            <a className={`button h-button is-rounded h-modal-close ${isLoading && 'is-loading'}`}
               onClick={()=> {reset(); setIsOpen(false)}} type="reset">
              Cancel
            </a>
            <button className={`button h-button is-primary is-raised is-rounded ${isLoading && 'is-loading'}`}>
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
