import {getDownloadURL, ref} from 'firebase/storage';
import {formatDate} from 'helpers.js';
import {useEffect, useState} from "react";
import ShowTask from "views/tasks/Show"
import useDropdown from "hooks/useDropdown";
import FeatherIcon from "feather-icons-react";
import UpdateTask from "views/tasks/Update";
import {doc, deleteDoc} from "firebase/firestore";
import {update} from "API.js";
import {useFirestore, useStorage} from "reactfire";
import Task from "models/Task";
import getProfileImage from "hooks/getProfileImage";
import moment from 'moment';
import truncate from 'truncate';


export default function KanbanCard({task, isInitial = false}) {

  // Services
  const firestore = useFirestore()
  const storage = useStorage()

  // States
  const [dropdownIsOpen, toggleDropdown] = useDropdown()
  const [showIsOpen, setShowIsOpen] = useState(false)
  const [editIsOpen, setEditIsOpen] = useState(false)
  const [assigneesImagesUrls, setAssigneesImagesUrls] = useState([])

  // Effects
  // Assignees images
  useEffect(() => {
    const getAssigneesImagesLinks = async () => {
      const links = [];
      for await (const id of task.assignees_ids) {
        const url = await getDownloadURL(ref(storage, `profile_images/${id}.jpg`))
        links.push(url)
      }
      setAssigneesImagesUrls(links)
    }
    if(task.assignees_ids?.length > 0) {
      // noinspection JSIgnoredPromiseFromCall
      getAssigneesImagesLinks()
    }
  }, [task.assignees_ids])

  // Hooks
  const clientImgURL = getProfileImage(task.client_id)


  // Methods
  const handleApprove = async (e) => {
    e.preventDefault()
    const taskRef = doc(firestore, "tasks", task.id)
    await update(taskRef, {status: Task.STATUS.APPROVED})
  }

  const handleDelete = async (ev) => {
    ev.preventDefault()
    if (!confirm('Are you sure you want to delete this task?')) return;
    await deleteDoc(doc(firestore, "tasks", task.id))
  }


  // Variables
  const createdAt = moment(task.created_at);

  return(
    <>
      {isInitial ?
        <div className="kanban-card is-new" style={{
          backgroundColor: moment(task.creation_date).isAfter(moment().endOf('month')) ? 'antiquewhite' : '#fff'
        }}>
          <div className="card-body">
            <div className="is-flex is-justify-content-space-between" onClick={() => setEditIsOpen(true)}>
              <h6 className="card-title is-size-7">Task # : {task.order}</h6>
              <div>
                <p className="is-size-7">{createdAt.format("DD/MM/YYYY")}</p>
                <p className="is-size-7 has-text-right">{createdAt.format("hh:mm A")}</p>
              </div>
            </div>
            <div className="new-avatar">
              <div className="avatar-wrapper is-info">
                <img className="task-owner" src={clientImgURL} alt=""/>
              </div>
            </div>
            <h4 className="card-title" dir="auto" onClick={() => setEditIsOpen(true)}>{truncate(task.title, 60)}</h4>
            <p dir="auto" onClick={() => setEditIsOpen(true)}>{truncate(task.content, 60)}</p>
          </div>
          <footer className="card-footer">
            <a href="#" className="card-footer-item" onClick={() => setEditIsOpen(true)}>Edit</a>
            <a href="#" className="card-footer-item" onClick={handleApprove}>Approve</a>
          </footer>
        </div> :
        <div className="kanban-card" style={{
          backgroundColor: moment(task.creation_date).isAfter(moment().endOf('month')) ? 'antiquewhite' : '#fff'
        }}>
          <div className="card-body">
            <div className="is-flex is-justify-content-space-between" onClick={() => setEditIsOpen(true)}>
              <h6 className="card-title is-size-7">Task # : {task.order}</h6>
              <div>
                <p className="is-size-7">{createdAt.format("DD/MM/YYYY")}</p>
                <p className="is-size-7 has-text-right">{createdAt.format("hh:mm A")}</p>
              </div>
            </div>
            <h4 className="card-title" dir="auto" onClick={() => setEditIsOpen(true)}>
              {truncate(task.content, 60)}
            </h4>
            <div className="kanban-card-stats" onClick={() => setEditIsOpen(true)}>
              <span>
                Assignee:
              </span>
              <div className="avatar-stack">
                {assigneesImagesUrls?.map((img, index) =>
                  <div className="h-avatar is-small" key={index}>
                    <img className="avatar" src={img} alt="None"/>
                  </div>)}
              </div>
            </div>
            <div className="kanban-card-stats">
              <span>
                <FeatherIcon icon={'clock'}/>
                {formatDate(task.creation_date)}
              </span>
              <div className="avatar-stack">
                <div className="h-avatar is-small">
                  <img className="avatar" src={clientImgURL} alt=""/>
                </div>
                <div className="is-end">
                  <div>
                    <div onClick={toggleDropdown}
                         className={`dropdown is-spaced is-dots is-right dropdown-trigger is-pushed-mobile ${dropdownIsOpen && 'is-active'}`}>
                      <div className="is-trigger" aria-haspopup="true">
                        <FeatherIcon icon={'more-vertical'}/>
                      </div>
                      <div className="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                          <a href={"#"} className="dropdown-item is-media" onClick={() => setShowIsOpen(true)}>
                            <div className="icon">
                              <i className="lnil lnil-pointer"/>
                            </div>
                            <div className="meta">
                              <span>Show</span>
                              <span>Show task details</span>
                            </div>
                          </a>
                          <a href={"#"} className="dropdown-item is-media" onClick={handleDelete}>
                            <div className="icon">
                              <i className="lnil lnil-trash-can"/>
                            </div>
                            <div className="meta">
                              <span>Delete</span>
                              <span>Delete task</span>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      {showIsOpen && <ShowTask isOpen={showIsOpen} setIsOpen={setShowIsOpen} task={task}/>}
      {editIsOpen && <UpdateTask task={task} setIsOpen={setEditIsOpen}/>}
    </>
  )
}
