import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


export default function Gallery({images, isMulti = false}) {

  let plugins = [lgZoom];
  if(isMulti) {
    plugins = plugins.concat(lgThumbnail)
  }

  return (
    <div className="App">
      <LightGallery
        plugins={plugins}
        mode="lg-fade"
      >
        {images.map( (image, index) => (
          <a
            key={index}
            className="gallery-item"
            data-src={image}
          >
            <img className="img-responsive" src={image} alt={`preview-${index+1}`} />
          </a>
        ))}
      </LightGallery>
    </div>
  );
}
