// noinspection ES6CheckImport
import React, {useContext, useEffect, useState} from 'react'
import constants from "constants.js";

const LayoutContext = React.createContext(undefined)

export function useLayout() {
  return useContext(LayoutContext)
}


export function LayoutProvider({children}) {

  // States
  const [theme, setTheme] = useState(localStorage.getItem(constants.THEME) || constants.LIGHT)
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState({
    createTask: false,
    listTask: false
  })

  // Effects
  useEffect(() => {
    document.querySelector('html').className = `is-${theme}`
    document.querySelector('body').className = `is-${theme}`
  }, [theme])

  useEffect(() => {
    setTimeout(() => {
      if(sidebarIsOpen){
        window.addEventListener('click', toggleSidebar)
      }
      else{
        window.removeEventListener('click', toggleSidebar)
      }
    }, 0)
  })

  // Methods
  function switchTheme(){
    const newTheme = theme === constants.LIGHT ? constants.DARK : constants.LIGHT;
    localStorage.setItem(constants.THEME, newTheme)
    setTheme(newTheme)
  }

  function isLightTheme() {
    return theme === constants.LIGHT;
  }

  function setLightTheme() {
    localStorage.setItem(constants.THEME, constants.LIGHT)
    setTheme(constants.LIGHT)
  }

  function isDarkTheme() {
    return theme === constants.DARK;
  }

  function toggleSidebar() {
    setSidebarIsOpen(!sidebarIsOpen)
    window.removeEventListener('click', toggleSidebar)
  }

  function activeSidebarClass() {
    return sidebarIsOpen && 'is-active';
  }

  function toggleModal(modalName) {
    setModalIsOpen({...modalIsOpen, ...{[modalName]: !modalIsOpen[modalName]}})
  }

  const value = {
    theme,
    switchTheme,
    isLightTheme,
    isDarkTheme,
    setLightTheme,
    sidebarIsOpen,
    toggleSidebar,
    activeSidebarClass,
    modalIsOpen,
    toggleModal
  }

  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  )
}
